import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout headSize={'small'}>
    <SEO title="Thank You" />
    <div css={`text-align:center;padding:5rem;`}>
      <h1>Thank you for contacting us.</h1>
      <p>life is great, have a good one</p>
    </div>
  </Layout>
)
